// import { SplitText } from '@cyriacbr/react-split-text';
import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import thumbnailplaceholder from '../assets/thumbnailplaceholder.png'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

// import Fetcher from '../components/Fetcher.tsx';
// import Fetch from '../components/Fetch.js';
import { useFetch } from '../components/FetchContext';

gsap.registerPlugin(ScrollTrigger);
// function TitleAnimation ({text}){
//   return(
    
//   )
// }

const SortButtons = ({ buttons, onSelectionChange }) => {
  const [activeButton, setActiveButton] = useState(buttons[0]);

  const handleButtonClick = (label) => {
    setActiveButton(label);
    if (onSelectionChange) {
      onSelectionChange(label); // Notify parent of the selected value
    }
  };

  return (
    <div className="space-y-2 lg:space-y-0 flex flex-col lg:flex-row lg:space-x-4">
      {buttons.map((label) => (
        <button
          key={label}
          className={`sort-btn  ${activeButton === label ? 'active' : ''}`}
          onClick={() => handleButtonClick(label)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};


function Thumbnail({ title, date, type, involvement, genre, src, projurl = '/', titleRef, dateRef, typeRef }) {
  const imgRef = useRef(null);
  const boxRef = useRef(null);
  

  const updateText = () => {
    // You can access the span element through spanRef
    function convertArrayToString(categories) {
      if (!Array.isArray(categories)) {
        // console.error("categories is not an array:", categories);
        return ""; // Return an empty string or a fallback value
      }
      if (categories.includes("Design") && categories.includes("Development")) {
        return "Design & Development";
      }
      return categories
    }

    function formatDate(date) {
      try {
        if (!(date instanceof Date)) {
          throw new Error("Input is not a valid Date object");
        }
        return date.toLocaleDateString("en-US", { year: "numeric", month: "long" });
      } catch (error) {
        return ''; // Return null or an appropriate fallback value in case of error
      }
    }

    if (titleRef.current) {
      titleRef.current.textContent = title;
      // bgtitleRef.current.textContent = text;
    }
    if (typeRef.current) {
      typeRef.current.textContent = `${type}${(genre) ? ',' : ''} ${genre}`;
    }
    if (dateRef.current) {
      dateRef.current.textContent = `${convertArrayToString(involvement)}${(date) ? ',' : ''} ${formatDate(date)}`;
    }
  };


  useGSAP(() => {
  // useLayoutEffect(() => {
    let mm = gsap.matchMedia()
    let triggerpos = 22
    mm.add("(min-width: 1024px)", () => {
      triggerpos = 26
    })
    if (!imgRef.current) return;

    // const initializeGsap = () => {
      // if (!containerRef.current || !imgRef.current || !boxRef.current) {
      //   // console.log('Refs not ready, retrying...');
      //   setTimeout(initializeGsap, 50); // Retry after 50ms
      //   return;
      // }
  
      // console.log('Initializingxw GSAP with refs:', containerRef, imgRef, boxRef);
  
      let ctx = gsap.context(() => {
        gsap.to(imgRef.current, {
          css: {
            filter: 'grayscale(0%) brightness(100%)',
            '-webkit-filter': 'grayscale(0%) brightness(100%)',
            scale: '1',
          },
          duration: 0.3,
          ease: 'power1.out',
          scrollTrigger: {
            scroller: ".scrollcontainerref",
            trigger: boxRef.current,
            start: `top ${triggerpos}%`,
            end: `top+=100% ${triggerpos}%`,
            toggleActions: 'play reverse play reverse',
            // markers: true,
            onEnter: updateText,
            onEnterBack: updateText,
          },
        });
        // ScrollTrigger.create({
        //   scroller: containerRef.current,
        //   trigger: boxRef.current,
        //   start: `top ${triggerpos}%`, // Adjust trigger position as needed
        //   end: `top+=100% ${triggerpos}%`,
        //   toggleActions: 'play reverse play reverse',
        //   onEnter: () => {
        //     imgRef.current.classList.add('active');
        //     updateText();
        //   },
        //   onEnterBack: () => {
        //     imgRef.current.classList.add('active');
        //     updateText();
        //   },
        //   onLeave: () => imgRef.current ? imgRef.current.classList.remove('active') : null,
        //   onLeaveBack: () => imgRef.current ? imgRef.current.classList.remove('active') : null,
        //   // markers: true, // Uncomment to debug
        // });
      });
      return () => {
        // console.log('Reverting GSAP context');
        ctx.revert();
        ctx.kill();
      };
  
    // };
  
    // initializeGsap();
      
  // },[containerRef.current]);
  });

  return (
    <div ref={boxRef}
    className='h-32 lg:h-48'
    style={{
      maxHeight:'100%',
    }}>
      <a className='h-full' href={projurl} target="_blank" rel="noreferrer"
      // border-[1px] border-white/60 bg-white/10'
      >
        <img ref={imgRef}
        style={{filter:'grayscale(100%) brightness(70%)'}}
        className='thumbnail-img h-full object-cover aspect-video origin-right scale-90'
          src={src}
        />
      </a>
    </div>
  );
}

function InfiniteLooper({
  speed,
  direction,
  text,
}) {
  const [looperInstances, setLooperInstances] = useState(1);
  const [velocity, setVelocity] = useState(1);
  const outerRef = useRef(null);
  const innerRef = useRef(null);

  const spanRef = useRef();

  const setupInstances = useCallback(() => {
      if (!innerRef?.current || !outerRef?.current) return;

      const { width } = innerRef.current.getBoundingClientRect();

      const { width: parentWidth } = outerRef.current.getBoundingClientRect();

      const instanceWidth = width / innerRef.current.children.length;

      if (width < parentWidth + instanceWidth) {
          setLooperInstances(looperInstances + Math.ceil(parentWidth / width));
      }
  }, [looperInstances]);

  // var velocity = 0

  useEffect(() => {
      setupInstances();
      if (spanRef.current) {
        setVelocity(spanRef.current.offsetWidth/speed)
      }
  }, [text,setupInstances]);

  return (
    <div className="looper" ref={outerRef}>
      <div className="looper__innerList" ref={innerRef}>
        {[...Array(looperInstances)].map((_, ind) => (
          <div
            key={ind}
            className="looper__listInstance"
            style={{
              animationDuration: `${velocity}s`,
              animationDirection: direction === "right" ? "reverse" : "normal",
            }}
          >
            <span ref={spanRef} className='text-[20rem] uppercase italic leading-none text-white whitespace-nowrap font-bold'>{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function ProjectComp({data}) {
  // const [refsReady, setRefsReady] = useState(false)
  // const containerRef = useRef(null)
  // const parentRef = useRef(null)
  const titleRef = useRef(null)
  const dateRef = useRef(null)
  const typeRef = useRef(null)
  const textLoopRef = useRef(null)
  const [bgTitleKey, setBgTitleKey] = useState(0);

  const [involvement, setInvolvement] = useState('All')
  const [type, setType] = useState('All')

  const synchronizeText = () => {
    if (titleRef.current) {
      const text = titleRef.current.textContent.replace(/\s+/g, '') || ''; // Get the text content
      setBgTitleKey(text); // Sync it to bgtitleRef
    }
  };

  // Set up synchronization when titleRef updates
  useEffect(() => {

    const observer = new MutationObserver(() => {
      synchronizeText();
    });

    if (titleRef.current) {
      // Observe changes to the content of titleRef
      observer.observe(titleRef.current, { childList: true, characterData: true, subtree: true });
    }

    // Cleanup the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);


  useEffect(() => {
    if(involvement==='Design & Dev'){
      setInvolvement(["Design", "Development"])
    }
  }, [involvement]);

  // useEffect(() => {
  //   // Reset `refsReady` to false
  //   // setRefsReady(false);
  //   // console.log('reset')
  
  //   // Wait for the `containerRef` to be ready again
  //   if (containerRef.current) {
  //     setRefsReady(true); // Trigger re-render when refs are ready
  //   }
  // }, [containerRef, involvement, type]);
  

  const filteredNames = Array.isArray(data) 
  ? data
      .filter((dataItem) => involvement === 'All' || dataItem.devtype.includes(involvement) ||
        (Array.isArray(dataItem.devtype) &&
        dataItem.devtype.length === involvement.length &&
        dataItem.devtype.every((category) => involvement.includes(category)))
      )
      .filter((dataItem) => type === 'All' || type === dataItem.type)
  : [];
  // const filteredNames = names
  // .filter((name) => involvement === 'All' || name.involvement.includes(involvement) || 
  //   (Array.isArray(name.involvement) &&
  //   name.involvement.length === involvement.length &&
  //   name.involvement.every((category) => involvement.includes(category)))
  // )
  // .filter((name) => type === 'All' || type === name.type);
  // const filteredNames = names
  // .filter((name) => involvement === 'All' || involvement === name.involvement)
  // .filter((name) => type === 'All' || type === name.type);

  // Check if the filteredNames array is empty
  const isEmpty = filteredNames.length === 0;

  return (
    <>
      <div ref={textLoopRef} className='fixed lg:block hidden bottom-16 w-full opacity-5 pageref'>
        <InfiniteLooper speed="250" direction="left" text={bgTitleKey}>
        </InfiniteLooper>
      </div>
      <div className="w-full h-full flex items-end justify-between projectpage realtive">
        <div className='w-full fixed top-1/3 lg:top-1/4 z-30 pointer-events-none'>
          <div className='flex w-full items-end'>
            <span ref={titleRef} className='absolute -translate-y-12 lg:translate-y-0 lg:relative font-main font-extralight text-2xl lg:text-5xl mr-4 text-white whitespace-nowrap'/>
            <div className='relative text-xs lg:text-sm flex-grow h-[1px] bg-[#5f5f5f] mb-2'>
              <span ref={typeRef} className='absolute bottom-1 drop-shadow-md font-semibold text-white'/>
              <span ref={dateRef} className='absolute top-1 drop-shadow-md font-semibold text-white'/>
            </div>
          </div>
        </div>
        <div className='fixed top-[calc(33%+64px)] lg:bottom-auto lg:top-1/2 text-xs lg:text-base text-white font-semibold z-20'>
          <div className='flex space-x-4'>
            <SortButtons buttons={['All', 'Design', 'Development', 'Design & Dev']} onSelectionChange={setInvolvement} />
          </div>
          <div className='h-[1px] w-full my-2 bg-white/5'/>
          <div className='flex space-x-4'>
            <SortButtons buttons={['All', 'Personal', 'Commission', 'Corporate']} onSelectionChange={setType} />
          </div>
        </div>
        <AnimatePresence mode='wait'
        // onExitComplete={() => {
        //   console.log("Exit animation completed");
        //   setRefsReady(false); // Ensure refs are reset after exit animation

        //   if (containerRef.current) {
        //     setRefsReady(true); // Trigger re-render when animation completes
        //     console.log('call')
        //   }
        // }}
        >
          <motion.div key={involvement+type} style={{scrollBehavior: 'smooth'}} className='w-full scrollcontainerref text-right h-full overflow-scroll projectpage scrollbar-hide'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          // onAnimationComplete={() => {
          //   if (containerRef.current) {
          //     setRefsReady(true); // Trigger re-render when animation completes
          //     console.log('call')
          //   }
          // }}
          >
              <div className='h-1/5'/>
              <div className='flex flex-col items-end lg:max-w-[33%] ml-auto'> 
                {/* <img className='h-40' src={thumbnailplaceholder}/> */}
                {isEmpty ? (
                  <Thumbnail
                    key={'untitled'}
                    src={thumbnailplaceholder} // Replace with your thumbnailplaceholder
                    title={'No projects found'}
                    date={''}
                    type={''}
                    involvement={''}
                    genre={''}
                    // containerRef={containerRef}
                    // parentRef={parentRef}
                    titleRef={titleRef}
                    dateRef={dateRef}
                    typeRef={typeRef}
                  />
                ) : (
                  filteredNames.map((data, index) => (
                  
                    <Thumbnail
                      key={index}
                      src={data.thumbnail} // Replace with your thumbnailplaceholder
                      title={data.name}
                      date={data.created_on}
                      type={data.type}
                      involvement={data.devtype}
                      genre={data.genre}
                      projurl={data.project_url}
                      // containerRef={containerRef}
                      // parentRef={parentRef}
                      titleRef={titleRef}
                      dateRef={dateRef}
                      typeRef={typeRef}
                    />
                  
                  )))}
              </div>
              <div className='h-[calc(75%-6rem)] lg:h-[calc(75%-10rem)]' />
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
}

function Projects () {
  const data = useFetch()

  return (
    <ProjectComp data={data}/> 
  )
}
// function Projects () {
//   const [data, setData] = useState(null)
//   // const [loading, setLoading] = useState(true)

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const fetchedData = await Fetch();
//         setData(fetchedData); // Set the fetched data
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       } finally {
//         // setLoading(false); // Stop loading once the data is fetched
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <AnimatePresence mode='wait'>
//       <motion.div
//         className='w-full h-full relative'
//         key={data}
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         exit={{ opacity: 0 }}
//         transition={{ duration: 0.2 }}
//       >
//         {data ?
//         <ProjectComp data={data}/> : 
//         <div className='bottom-24 fixed right-12 lg:right-24 text-white'>
//           <svg
//           className='animate-spin w-12 h-12 lg:w-20 lg:h-20'
//             viewBox="0 0 1024 1024"
//             fill="currentColor"
//           >
//             <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
//           </svg>
//         </div>}
//       </motion.div>
//     </AnimatePresence>
//   )
// }

export default Projects



