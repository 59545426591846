import React from 'react';
import { easeInOut, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

function Page({ children }) {
  const location = useLocation();

  // Define animations for specific routes
  const isIndexPage = location.pathname === '/';
  const variants = isIndexPage
    ? {
        initial: { opacity: 0, filter:'blur(5px)', scale:0.9,  transition: { duration: 0.5, ease: easeInOut }},
        // animate: { opacity: 1, y: '0', scaleY:1, filter:'blur(0px)',},
        animate: { opacity: 1, scale:1, filter:'blur(0px)',},
        exit: { opacity: 0, filter:'blur(5px)', scale:1.5,  transition: { duration: 0.5, ease: easeInOut }},
        // exit: { opacity: 0, y: '-100vh', scaleY:3, transition: { duration: 1, ease: [1,0,1,0.34] }},
      }
    : {
        initial: { opacity: 0, filter:'blur(2px)',  transition: { duration: 0.5, ease: easeInOut } },
        animate: { opacity: 1, filter:'blur(0px)', },
        exit: { opacity: 0, filter:'blur(2px)',  transition: { duration: 0.5, ease: easeInOut } },
      };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

export default Page;
