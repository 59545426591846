import { Canvas, useFrame, useThree } from '@react-three/fiber'
// import { OrbitControls } from '@react-three/drei';
// import * as THREE from 'three';

import React from 'react';
import { useEffect, useRef } from 'react';
const Spheres = React.memo(({ amount, color }) => {
    const sphereRefs = useRef([]);

    // Update sphere positions for drifting effect on every frame
    useFrame((state, delta) => {
        sphereRefs.current.forEach((sphere) => {
            if (sphere) {
                const driftSpeed = 0.05;  // Drift speed multiplier (adjust as needed)
                const randomFactor = Math.random() - 0.5; // Random drift direction (-0.5 to 0.5)

                // Apply the drift, scaled by delta for smoothness
                sphere.position.x += randomFactor * driftSpeed * delta;
                sphere.position.y += randomFactor * driftSpeed * delta;
                sphere.position.z += randomFactor * driftSpeed * delta;
            }
        });
    });

    

    return (
        <>
            {Array.from({ length: amount }).map((_, i) => (
                <mesh
                    key={i}
                    ref={(el) => (sphereRefs.current[i] = el)} // Store ref to each sphere
                    position={[
                        (Math.random() * window.innerWidth - window.innerWidth * 0.5),
                        (Math.random() * window.innerHeight - window.innerHeight * 0.5),
                        (Math.random() * 400)
                    ]}
                >
                    <sphereGeometry args={[0.5, 6, 6]} />
                    <meshStandardMaterial color={color} />
                </mesh>
            ))}
        </>
    );
});

const MouseControlledCamera = () => {
    const { camera } = useThree();
    const mouseTolerance = 0.01;
    const smoothing = 0.2;

    useEffect(() => {
        let targetX = 0;
        let targetY = 0;

        const onMouseMove = (e) => {
            const centerX = window.innerWidth * 0.5;
            const centerY = window.innerHeight * 0.5;
            targetX = -(e.clientX - centerX) * mouseTolerance;
            targetY = (e.clientY - centerY) * mouseTolerance;
            camera.position.x += (targetX - camera.position.x) * smoothing;
            camera.position.y += (targetY - camera.position.y) * smoothing;
        };

        window.addEventListener("mousemove", onMouseMove);

        return () => {
            window.removeEventListener("mousemove", onMouseMove);
        };
    }, [camera, mouseTolerance]);

    return null;
};


const CameraController = ({ newposition, hypo }) => {
    useFrame((state) => {
        // Access the camera from the state
        const { camera } = state;
    
        // Interpolate the z position dynamically, keeping x and y unchanged
        const targetPosition = camera.position.clone();  // Clone the current position
        targetPosition.z = Number(newposition*10) + hypo ;   // Update only the z value

        // console.log(Number(newposition) + hypo)
    
        camera.position.lerp(targetPosition, 0.03); // Smooth transition to new z position
    });
    
      return null;
  };

export default function Particles({color, newposition}) {
    const hypo = Math.sqrt(window.innerWidth ** 2 - (window.innerWidth / 2) ** 2) / 2;
    // const [cameraPos, setCamPos] = useState({ x: 0, y: 0 });

    const cameraSettings = {
        fov: 90, 
        near: 0.1, 
        far: hypo+500, 
        position: [0, 0, hypo]
    }

    return (
        <div className='lg:blur-none blur-md' style={{ width: '100vw', height: '100vh' }}>
            <Canvas 
                camera={cameraSettings}
            >
                {/* <OrbitControls /> */}
                <CameraController newposition={newposition} hypo={hypo} />
                <MouseControlledCamera />
                <Spheres amount={500} color={color}/>
                <ambientLight intensity={1} />
                {/* <directionalLight color="white" position={[0, 5, 5]} /> */}
            </Canvas>
        </div>
    )
}

