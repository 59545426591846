import React, { createContext, useContext } from 'react';

const FetchContext = createContext();

export const useFetch = () => useContext(FetchContext);

export const FetchProvider = ({ children, fetch }) => (
  <FetchContext.Provider value={fetch}>
    {children}
  </FetchContext.Provider>
);
