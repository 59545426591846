// import { Link } from 'react-router-dom';

function Contact() {
  return (
    <div className="flex flex-col  w-full h-full justify-center items-end space-y-12">
      <div className='font-semibold text-white font-main flex flex-col items-end'>
        <a href='https://github.com/owoleya' target='_blank'  rel="noreferrer" className='inline-flex items-center opacity-100 hover:opacity-40 transition-opacity'>
          GitHub
          <svg className='ml-1' width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
          </svg>
        </a>
        <a href='https://www.linkedin.com/in/pyotr-lye-91982b89/' target='_blank'  rel="noreferrer" className='inline-flex items-center opacity-100 hover:opacity-40 transition-opacity'>
          LinkedIn
          <svg className='ml-1' width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
          </svg>
        </a>
        <a href='https://www.behance.net/pyotrlye' target='_blank'  rel="noreferrer" className='inline-flex items-center opacity-100 hover:opacity-40 transition-opacity'>
          Behance
          <svg className='ml-1' width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
          </svg>
        </a>
        <a href='https://www.instagram.com/pyor.t/' target='_blank'  rel="noreferrer" className='inline-flex items-center opacity-100 hover:opacity-40 transition-opacity'>
          Instagram
          <svg className='ml-1' width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
          </svg>
        </a>
      </div>
      <div className='font-semibold text-white font-main items-end flex flex-col'>
        <a href='https://forms.gle/WDhTvcBrP3o8tzqUA' target='_blank'  rel="noreferrer" className='inline-flex items-center opacity-100 hover:opacity-40 transition-opacity'>
          Commissions
          <svg className='ml-1' width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
          </svg>
        </a>
        <a href='https://drive.google.com/file/d/1ywsTS9UfA_TopOgpRS5UC5NwC7XkgkI3/view?usp=sharing' target='_blank'  rel="noreferrer" className='inline-flex items-center opacity-100 hover:opacity-40 transition-opacity'>
          View my Resume
          <svg className='ml-1' width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"/>
          </svg>
        </a>
      </div>

      <div className=' w-full text-white font-main items-end flex flex-col'>
        <a href='mailto:pyotrlye@gmail.com?subject=Inquiry&body=Hi%20Pyotr%2C%0A%0A' target='_blank'  rel="noreferrer" className='text-2xl max-w-[80vw] lg:text-5xl origin-right font-extralight italic mb-4 tracking-normal hover:tracking-wide opacity-100 hover:opacity-50 transition-all'>
          pyotrlye@gmail.com
        </a>
        <p className='text-end max-w-[80vw] text-xs text-balance lg:text-sm font-semibold'>
          For any inquiries or commissions, contact this email.<br/>
          ...or use <a href='https://forms.gle/WDhTvcBrP3o8tzqUA' target='_blank'  rel="noreferrer" className='opacity-100 hover:opacity-40 transition-opacity underline underline-offset-4'>this form</a> for commissions and details.
        </p>
      </div>
      
    </div>
  );
}

export default Contact;
