import { signInAnon } from "../firebase";

function transformCMSData(data) {
  // Ensure `data` is an array
  if (!Array.isArray(data) || data.length === 0) {
    return []; // Return an empty array if `data` is invalid
  }

  // Map over the documents array
  return data.map((doc) => {
    const fields = doc.fields || {};

    // Convert devtype to an array of strings, safely handling missing properties
    const devtypeArray = (fields.devtype?.arrayValue?.values || []).map(
      (item) => item?.stringValue || '' // Default to an empty string if stringValue is undefined
    );

    return {
      name: fields.name?.stringValue || '', // Default to an empty string if name is missing
      type: fields.type?.stringValue || '', // Default to an empty string if type is missing
      devtype: devtypeArray,
      thumbnail: fields.thumbnail?.stringValue || '', // Default to an empty string if thumbnail is missing
      project_url: fields.project_url?.stringValue || '', // Default to an empty string if project_url is missing
      genre: fields.genre?.stringValue || '', // Default to an empty string if project_url is missing
      created_on: fields.created_on?.timestampValue
        ? new Date(fields.created_on.timestampValue) // Convert to Date object
        : null, // Default to null if created_on is missing
    };
  });
}

async function Fetch() {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID; // Replace with your Firebase project ID
  // const collection = 'projects';
  const url = `https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents:runQuery`;
  let data = null;
  let error = null;

  const token = await signInAnon(); // Get token after signing in
  // const token = process.env.REACT_APP_FIREBASE_PERSONAL_ACCESS_TOKEN
  // console.log(token)
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`, // Use Bearer token format
        'Content-Type': 'application/json',
        'Connection': 'close',
      },
      body: JSON.stringify({
        "structuredQuery": {
          "from": [
            {
              "collectionId": "projects"
            }
          ],
          "orderBy": [
            {
              "field": {
                "fieldPath": "created_on"
              },
              "direction": "DESCENDING"
            }
          ]
        }
      })
    });

    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    // console.log(response)

    const jsonData = await response.json();
    data = jsonData
    .filter(item => item.document) // Ensure `document` exists
    .map(item => item.document);
    // data = jsonData.documents;
  } catch (err) {
    error = err.message;
  }

  // Return transformed data if no error
  if (data) {
    // console.log(transformCMSData(data)) ;
    return transformCMSData(data);
  } else {
    console.error('Error:', error);
    return []; // Return an empty array if there was an error
  }
}

export default Fetch
