
import '../App.css';
import Logo from '../assets/animations/logo';

import {  Link } from 'react-router-dom';



function Main() {

  const pages = ["about","projects","contact"]


  return (
    <section className="relative z-30 h-screen lg:h-screen w-full p-4 lg:p-20 flex lg:flex-row items-center lg:justify-between">

        <div className='flex absolute lg:relative top-12 lg:top-auto origin-bottom-left scale-[0.4] lg:scale-100'>
            <Logo/>
            {/* <span className='text-[10rem] -translate-x-6 select-none -translate-y-[15px] font-main text-white font-thin'>
            .xyz
            </span> */}
        </div>

        <div className='hidden lg:block h-24 w-[1px] bg-white/10' />

        <div className='flex flex-col lg:items-start items-end lg:right-auto right-8 lg:top-auto top-1/2 absolute lg:relative lg:mx-12 space-y-2 text-white text-base lg:text-xl font-semibold font-main'>
            {pages.map((item, index) => (
            // <Link to="/about">About</Link>

            <Link className='capitalize cursor-pointer group relative' key={index} to={`/${item}`}>
                {/* <span className='absolute group-hover:opacity-100 opacity-0 transition-opacity mx-1'>•</span> */}
                <span className='group-hover:opacity-50 opacity-100 transition-all'>{item}</span> 
            </Link>
            ))}
        </div>

    </section>
      )}

export default Main;
